@import url(https://fonts.googleapis.com/css?family=Asap|Noto+Sans+TC|Special+Elite&display=swap);

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Special Elite", "Asap", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  font-family: 'Special Elite', cursive;
}

h1, h2, h3, h4 {
  font-family: 'Special Elite', cursive;
  color: #753ECE;
  padding: 10px;
}

#title {
  color: #753ECE;
}

p {
  font-family: 'courier';
}

#description {
  font-size: 10px;
}

.content {
  font-family: 'courier';
}

#img-profile {
  text-align: center;
  border-radius: 100px;
}

title:hover {
  color: #4B4453;
}

/* color palette purple: #753ECE, dark grey: #4B4453 light grey: #B0A8B9, light purple: #BAA1D5 */
