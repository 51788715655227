@import url('https://fonts.googleapis.com/css?family=Asap|Noto+Sans+TC|Special+Elite&display=swap');

.App {
  font-family: 'Special Elite', cursive;
}

h1, h2, h3, h4 {
  font-family: 'Special Elite', cursive;
  color: #753ECE;
  padding: 10px;
}

#title {
  color: #753ECE;
}

p {
  font-family: 'courier';
}

#description {
  font-size: 10px;
}

.content {
  font-family: 'courier';
}

#img-profile {
  text-align: center;
  border-radius: 100px;
}

title:hover {
  color: #4B4453;
}

/* color palette purple: #753ECE, dark grey: #4B4453 light grey: #B0A8B9, light purple: #BAA1D5 */